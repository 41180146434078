.rf-chat-application-container {
    z-index: 1010 !important;
}

@media (max-width: 768px) {
    app-greeting-message {
        display: none !important;
    }
    .mobile-buttons-container {
        bottom: auto!important;
        right: 110px!important;
        top: 0.5vh!important;
    }
    .mobile-buttons-container .bot-help-button-container {
        width: 45px !important;
        height: 45px !important;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5) !important;
    }
}

@media (min-width: 769px) and (max-width: 1310px) {
    app-greeting-message {
        display: none !important;
    }
    .mobile-buttons-container {
        bottom: 80px !important;
    }
}